/* eslint-disable jsx-a11y/anchor-is-valid */
import { type FC } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ActionButton from '../../components/buttons/ActionButton';
import { useListView } from '../TenantListView';
import { Message } from '../../../utility/notifications/Message';
import useJwt from '../../../auth/jwt/useJwt';
import { getUserData } from '../../../utility/Utils';
import { emptySplitApi } from '../../../utility/emptySplitApi';
import routeNames from '../../../routing/routeNames';
import { setBoolean } from '../../../redux/BackToContentManagerPage';

interface Props {
  id: number | null;
  text: string;
  disabled: boolean;
}

const ImpersonateActionCell: FC<Props> = ({ id, text, disabled }) => {
  const { impersonateToggle, impersonateId: tenantId, } = useListView();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { jwt, } = useJwt();

  // If the tenantId is null, we are not in the impersonate modal
  const ImpersonateLoginAction = () => {
    if (!tenantId) {
      impersonateToggle(id);
    }
    if (tenantId && id) {
      handleImpersonationLogin(tenantId, id).then(() => { });
      impersonateToggle(null);
    }
  };

  const handleImpersonationLogin = async (tenantId: number, userId: number) => {
    jwt
      .impersonate({ tenantId, userId, })
      .then((res) => {
        if (res.status === 200) {
          const accessToken = res.data.impersonationToken;
          jwt.ImpersonatedAuthenticate(accessToken).then((res) => {
            if (res.status === 200) {
              const result = {
                accessToken: res.data.accessToken,
                impersonateData: {
                  roles: res.data.roles,
                  emailAdress: res.data.emailAdress,
                  tenantId,
                },
                userData: { ...getUserData(), isHost: false, },
              };

              // Sets a boolean where a user returns to the content page when they impersonated from there
              dispatch(setBoolean(location.pathname.includes(routeNames.contentPage)));
              jwt.setPermissions(res.data.grantedPermissions);
              jwt.setTenant(result);
              dispatch(emptySplitApi.util.resetApiState());

              navigate(routeNames.fallback);
            }
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          Message('Impersonation failed!', err.response.data.exception, 'error', {});
        }
      });
  };

  return (
    <ActionButton text={text} onClick={() => { ImpersonateLoginAction(); }} color="primary" disabled={disabled} />
  );
};

export { ImpersonateActionCell };
