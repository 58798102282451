import { type ElementType, lazy } from 'react';
import routeNames, { AppRoutes, routeNamesPrefix } from '../routeNames';
import {
  Common_Roles_View,
  Common_Users_View,
  Host_BiHub_View,
  Host_DocumentCabinet_View,
  Host_ImportFormSqlLogs_View,
  Host_Mapping_View,
  Host_Tenants_Edit,
  Host_Tenants_View,
  Tenant_Dashboards,
  Tenant_Documents_View,
  Tenant_ImportForms_View,
  Tenant_ManagementReports_View,
  Tenant_Mapping_View
} from '../../constants/permissions';

import SsrsContentWrapper from '../../views/content-manager/SSRS/PageView';
import { Error404 } from '../../views/errors/components/Error404';
import { MappingLayout } from '../../views/mapping/MappingLayout';
import TenantFallbackRoute from '../components/TenantFallbackRoute';

const Loadable = (Component: ElementType) => function (props: any) {
  return <Component {...props} />;
};

const PowerBiContentWrapper = Loadable(lazy(async () => await import('../../views/content-manager/PowerBi/PageView')));
const PaginatedReportContentWrapper = Loadable(lazy(async () => await import('../../views/content-manager/PaginatedReport/PageView')));
const ImportFormContentWrapper = Loadable(lazy(async () => await import('../../views/content-manager/importForms/PageView')));
const StatisticsContentWrapper = Loadable(lazy(async () => await import('../../views/statistics/PageView')));
const BiHubPage = Loadable(lazy(async () => await import('../../views/bi-hub/BiHubPage')));
const BiHubSchedularPage = Loadable(lazy(async () => await import('../../views/bi-hub/schedular/BiHubSchedularPage')));
const DocumentCabinetLogPage = Loadable(lazy(async () => await import('../../views/logs/DocumentCabinetLogPage')));
const TransactionMappingLogPage = Loadable(lazy(async () => await import('../../views/logs/TransactionMappingLogPage ')));
const EditionPage = Loadable(lazy(async () => await import('../../views/editions/EditionPage')));
const PowerBiPage = Loadable(lazy(async () => await import('../../views/powerBi/PowerBiPage')));
const PaginatedPage = Loadable(lazy(async () => await import('../../views/paginated-report/PaginatedReportPage')));
const PowerBiListPage = Loadable(lazy(async () => await import('../../views/powerBi/PowerBiListPage')));
const TenantPage = Loadable(lazy(async () => await import('../../views/tenant/TenantPage')));
const DocumentPage = Loadable(
  lazy(async () => await import('../../views/document-cabinet/DocumentCabinetPage'))
);
const UserPage = Loadable(lazy(async () => await import('../../views/user-management/UserPage')));
const RolePage = Loadable(lazy(async () => await import('../../views/roles/RolePage')));
const SsrsListPage = Loadable(lazy(async () => await import('../../views/ssrs/SsrsListPage')));
const SsrsPage = Loadable(lazy(async () => await import('../../views/ssrs/SsrsPage')));
const FaqPage = Loadable(lazy(async () => await import('../../views/faq/FaqPage')));
const SettingsPage = Loadable(lazy(async () => await import('../../views/settings/SettingsPage')));
const ImportFormListPage = Loadable(
  lazy(async () => await import('../../views/importForms/ImportFormListPage'))
);
const ImportFormPage = Loadable(lazy(async () => await import('../../views/importForms/ImportFormGridPage')));
const ImportFormLogs = Loadable(lazy(async () => await import('../../views/logs/ImportFormLogPage')));
const MappingLayoutTenant = Loadable(lazy(async () => await import('../../views/mapping/tenant/MappingLayoutTenant')));

// ** Merge Routes
const PrivateRoutes = [
  {
    path: routeNames.fallback,
    element: <TenantFallbackRoute />,
    meta: {
      layout: 'master',
    },
  },
  {
    path: routeNames.ssrs,
    element: <SsrsListPage />,
    meta: {
      layout: 'master',
      publicRoute: false,
      requiredPermission: Tenant_ManagementReports_View,
    },
  },
  {
    path: `${routeNames.ssrs}/:id`,
    element: <SsrsPage />,
    meta: {
      layout: 'master',
      publicRoute: false,
      requiredPermission: Tenant_ManagementReports_View,
    },
  },
  {
    path: routeNames.powerBi,
    element: <PowerBiListPage />,
    meta: {
      layout: 'master',
      publicRoute: false,
      requiredPermission: Tenant_Dashboards,
    },
  },
  {
    path: `${routeNames.powerBi}/:id`,
    element: <PowerBiPage />,
    meta: {
      layout: 'master',
      publicRoute: false,
      requiredPermission: Tenant_Dashboards,
    },
  },

  {
    path: `${routeNamesPrefix.ssrs_paginated}/:id`,
    element: <PaginatedPage />,
    meta: {
      layout: 'master',
      publicRoute: false,
      requiredPermission: Tenant_ManagementReports_View,
    },
  },
  {
    path: routeNames.user,
    element: <UserPage />,
    meta: {
      layout: 'master',
      publicRoute: false,
      requiredPermission: Common_Users_View,
    },
  },
  {
    path: routeNames.faq,
    element: <FaqPage />,
    meta: {
      layout: 'master',
      publicRoute: false,
    },
  },
  {
    path: routeNames.role,
    element: <RolePage />,
    meta: {
      layout: 'master',
      publicRoute: false,
      requiredPermission: Common_Roles_View,
    },
  },
  {
    path: routeNames.tenant,
    element: <TenantPage />,
    meta: {
      layout: 'master',
      publicRoute: false,
      requiredPermission: Host_Tenants_View,
    },
  },
  {
    path: routeNames.settings,
    element: <SettingsPage />,
    meta: {
      layout: 'master',
      publicRoute: false,
    },
  },
  {
    path: routeNames.documentCabinet,
    element: <DocumentPage />,
    meta: {
      layout: 'master',
      publicRoute: false,
      requiredPermission: Tenant_Documents_View,
    },
  },
  {
    path: routeNames.importForm,
    element: <ImportFormListPage />,
    meta: {
      layout: 'master',
      publicRoute: false,
      requiredPermission: Tenant_ImportForms_View,
    },
  },
  {
    path: `${routeNames.importForm}/:id`,
    element: <ImportFormPage />,
    meta: {
      layout: 'master',
      publicRoute: false,
      requiredPermission: Tenant_ImportForms_View,
    },
  },
  {
    path: `${routeNames.mapping}/:id/`,
    element: <MappingLayout />,
    meta: {
      layout: 'master',
      publicRoute: false,
      requiredPermission: Host_Mapping_View,
    },
  },
  {
    path: `${routeNames.tenantMapping}`,
    element: <MappingLayoutTenant />,
    meta: {
      layout: 'master',
      publicRoute: false,
      requiredPermission: Tenant_Mapping_View,
    },
  },
  {
    path: routeNames.importFormLogs,
    element: <ImportFormLogs />,
    meta: {
      layout: 'master',
      publicRoute: false,
      requiredPermission: Host_ImportFormSqlLogs_View,
    },
  },
  {
    path: routeNames.documentCabinetLogs,
    element: <DocumentCabinetLogPage />,
    meta: {
      layout: 'master',
      publicRoute: false,
      requiredPermission: Host_DocumentCabinet_View,
    },
  },

  {
    path: routeNames.transactionsHistoryLogs,
    element: <TransactionMappingLogPage />,
    meta: {
      layout: 'master',
      publicRoute: false,
      requiredPermission: Host_DocumentCabinet_View,
    },
  },
  {
    path: `${routeNames.tenant}/:id/content/overview`,
    element: <SsrsContentWrapper />,
    meta: {
      layout: 'master',
      publicRoute: false,
      requiredPermission: Host_Tenants_Edit,
    },
  },
  {
    path: `${routeNames.tenant}/:id/content/powerBi`,
    element: <PowerBiContentWrapper />,
    meta: {
      layout: 'master',
      publicRoute: false,
      requiredPermission: Host_Tenants_Edit,
    },
  },

  {
    path: `${routeNames.tenant}/:id${routeNames.contentPaginatedReport}`,
    element: <PaginatedReportContentWrapper />,
    meta: {
      layout: 'master',
      publicRoute: false,
      requiredPermission: Host_Tenants_Edit,
    },
  },
  {
    path: `${routeNames.tenant}/:id/content/importforms`,
    element: <ImportFormContentWrapper />,
    meta: {
      layout: 'master',
      publicRoute: false,
      requiredPermission: Host_Tenants_Edit,
    },
  },
  {
    path: routeNames.wildcard,
    meta: {
      layout: 'master',
    },
    element: <Error404 />,
  },
  {
    path: `${routeNames.biHub}`,
    element: <BiHubPage />,
    meta: {
      layout: 'master',
      publicRoute: false,
      requiredPermission: Host_BiHub_View,
    },
  },
  {
    path: `${routeNames.tenant}/:id${routeNames.statistics}`,
    element: <StatisticsContentWrapper />,
    meta: {
      layout: 'master',
      publicRoute: false,
      requiredPermission: Host_Tenants_Edit,
    },
  },

  {
    path: AppRoutes.editionPage.path,
    element: <EditionPage />,
    meta: {
      layout: 'master',
      publicRoute: false,
      requiredPermission: Host_BiHub_View,
    },
  },

  {
    path: AppRoutes.taskPage.path,
    element: <BiHubPage />,
    meta: {
      layout: 'master',
      publicRoute: false,
      requiredPermission: Host_BiHub_View,
    },
  },
  {
    path: AppRoutes.scheduler.path,
    element: <BiHubSchedularPage />,
    meta: {
      layout: 'master',
      publicRoute: false,
      requiredPermission: Host_BiHub_View,
    },
  }
];

export default PrivateRoutes;
