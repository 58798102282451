/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AuthenticateMasterResultModelResult,
  AuthenticateModel,
  AuthenticateTenantDtoResult,
  BiHubPipelineDtoResult,
  BiHubPipelineRunLogDtoArrayResult,
  BiHubScheduledDetailsDto,
  BiHubScheduledLogDtoPagedResultWithFiltersDtoResult,
  BooleanResult,
  BreadCrumbDtoListResult,
  ChangePasswordDto,
  CreateEditionRequestDto,
  CreateFolderRequestDto,
  CreateImportFormRequestDto,
  CreatePaginatedReportRequestDto,
  CreatePowerBiReportRequestDto,
  CreateRoleRequestDto,
  CreateSsrsReportRequestDto,
  CreateTenantRequestDto,
  CreateUserRequestDto,
  CreateViewGroupRequestDto,
  CurrentUserPermissionsDtoResult,
  DimMappingChangeLogDtoPagedResultWithFiltersDtoResult,
  DimTableDtoResult,
  DocumentCabinetLogDtoPagedResultDtoResult,
  DropdownItemDto,
  EditionPagedResultDtoResult,
  EmbedConfigResult,
  FileContentResultResult,
  FileDetailsDtoResult,
  FilePreviewDtoResult,
  FolderContentsDtoResult,
  FolderDtoResult,
  GetEditionDtoResult,
  GoogleAuthenticatorSettingsDto,
  HangFireJobDtoPagedResultDtoResult,
  ImpersonatedAuthenticateResultModel,
  ImpersonateInput,
  ImpersonateOutput,
  ImportFormDtoResult,
  ImportFormInfoDtoViewGroupCollectionDtoResult,
  ImportFormSqlLogDtoPagedResultDtoResult,
  Int32Result,
  MappingErrorDtoListResult,
  MasterUserDtoPagedResultDtoResult,
  MasterUserSettingsDto,
  MigatePowerBiReportRequestDto,
  MoveFileOrFolderType,
  OptionValueLabelResult,
  PaginatedReportDtoResult,
  PaginatedReportInfoDtoViewGroupCollectionDtoResult,
  PasswordResetDto,
  PatchTenantStatusDto,
  PermissionDtoListResultDto,
  PowerBiReportApiInfoListDtoResult,
  PowerBiReportDtoResult,
  PowerBiReportInfoDtoViewGroupCollectionDtoResult,
  PowerBiUtilizationDtoPagedResultDtoResult,
  ReportEmbedDetailsDtoResult,
  ReportMigrationViewModelResult,
  RoleDtoResult,
  RoleListDtoPagedResultDtoResult,
  RunBiHubPipelineFromHostRequestDto,
  RunBiHubPipelineFromTenantRequestDto,
  SendTwoFactorAuthCodeModel,
  SsrsReportEmbedDtoResult,
  SsrsReportInfoDtoViewGroupCollectionDtoResult,
  StringListResult,
  StringResult,
  TenantDtoResult,
  TenantUserInfoCollectionDtoResult,
  UpdateBiHubPipelineRequestDto,
  UpdateBiHubPipelineRunStatusRequestDto,
  UpdateBiHubPowerBiDatasetRefreshRequestDto,
  UpdateBiHubScheduledJobDto,
  UpdateBiHubTaskRunStatusRequestDto,
  UpdateDimTableRequestDto,
  UpdateDimTableTenantRequestDto,
  UpdateEditionRequestDto,
  UpdateFileDetailsDto,
  UpdateFolderRequestDto,
  UpdateGoogleAuthenticatorKeyOutput,
  UpdateImportFormDataRequestDto,
  UpdateImportFormPropertiesRequestDto,
  UpdateImportFormPropertiesRequestDtoResult,
  UpdateMasterUserFavoriteTenantDto,
  UpdateMasterUserSettingsDto,
  UpdatePaginatedReportRequestDto,
  UpdatePowerBiReportRequestDto,
  UpdateRoleRequestDto,
  UpdateSsrsReportRequestDto,
  UpdateSsrsReportRequestDtoResult,
  UpdateTenantRequestDto,
  UpdateUserRequestDto,
  UpdateViewGroupCollectionRequestDto,
  UserDtoPagedResultDtoResult,
  UserDtoResult,
  ViewGroupInfoDtoListResult,
  ViewGroupType,
} from "./data-contracts";

export namespace Api {
  /**
   * No description     * @tags Auth
   * @name AuthAuthenticateTenantCreate
   * @request POST:/api/Auth/AuthenticateTenant
   * @secure
   */
  export namespace AuthAuthenticateTenantCreate {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/api/Auth/AuthenticateTenant",
      method: "POST",
    };
    export type RequestQuery = {
      /** @format int32 */
      tenantId?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = AuthenticateTenantDtoResult;
  }

  /**
   * No description     * @tags Auth
   * @name AuthGetTenantsForMasterUserList
   * @request GET:/api/Auth/GetTenantsForMasterUser
   * @secure
   */
  export namespace AuthGetTenantsForMasterUserList {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/api/Auth/GetTenantsForMasterUser",
      method: "GET",
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = TenantUserInfoCollectionDtoResult;
  }

  /**
   * No description     * @tags Auth
   * @name AuthAuthenticateMasterUserCreate
   * @request POST:/api/Auth/AuthenticateMasterUser
   * @secure
   */
  export namespace AuthAuthenticateMasterUserCreate {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/api/Auth/AuthenticateMasterUser",
      method: "POST",
    };
    export type RequestQuery = {};
    export type RequestBody = AuthenticateModel;
    export type RequestHeaders = {};
    export type ResponseBody = AuthenticateMasterResultModelResult;
  }

  /**
   * No description     * @tags Auth
   * @name AuthImpersonateCreate
   * @request POST:/api/Auth/Impersonate
   * @secure
   */
  export namespace AuthImpersonateCreate {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/api/Auth/Impersonate",
      method: "POST",
    };
    export type RequestQuery = {};
    export type RequestBody = ImpersonateInput;
    export type RequestHeaders = {};
    export type ResponseBody = ImpersonateOutput;
  }

  /**
   * No description     * @tags Auth
   * @name AuthImpersonatedAuthenticateCreate
   * @request POST:/api/Auth/ImpersonatedAuthenticate
   * @secure
   */
  export namespace AuthImpersonatedAuthenticateCreate {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/api/Auth/ImpersonatedAuthenticate",
      method: "POST",
    };
    export type RequestQuery = {
      impersonationToken?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = ImpersonatedAuthenticateResultModel;
  }

  /**
   * No description     * @tags Auth
   * @name AuthDelegatedImpersonatedAuthenticateCreate
   * @request POST:/api/Auth/DelegatedImpersonatedAuthenticate
   * @secure
   */
  export namespace AuthDelegatedImpersonatedAuthenticateCreate {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/api/Auth/DelegatedImpersonatedAuthenticate",
      method: "POST",
    };
    export type RequestQuery = {
      /** @format int64 */
      userDelegationId?: number;
      impersonationToken?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = ImpersonatedAuthenticateResultModel;
  }

  /**
   * No description     * @tags Auth
   * @name AuthSendTwoFactorAuthCodeCreate
   * @request POST:/api/Auth/SendTwoFactorAuthCode
   * @secure
   */
  export namespace AuthSendTwoFactorAuthCodeCreate {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/api/Auth/SendTwoFactorAuthCode",
      method: "POST",
    };
    export type RequestQuery = {};
    export type RequestBody = SendTwoFactorAuthCodeModel;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description     * @tags BiHub
   * @name V1TenantBiHubRunCreate
   * @request POST:/api/v1/tenant/BiHub/Run
   * @secure
   */
  export namespace V1TenantBiHubRunCreate {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/tenant/BiHub/Run",
      method: "POST",
    };
    export type RequestQuery = {};
    export type RequestBody = RunBiHubPipelineFromTenantRequestDto;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description     * @tags BiHub
   * @name V1TenantBiHubStatusList
   * @request GET:/api/v1/tenant/BiHub/Status
   * @secure
   */
  export namespace V1TenantBiHubStatusList {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/tenant/BiHub/Status",
      method: "GET",
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description     * @tags BiHub
   * @name V1HostBiHubTenantsList
   * @request GET:/api/v1/host/BiHub/Tenants
   * @secure
   */
  export namespace V1HostBiHubTenantsList {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/host/BiHub/Tenants",
      method: "GET",
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = BiHubPipelineDtoResult;
  }

  /**
   * No description     * @tags BiHub
   * @name V1HostBiHubDetail
   * @request GET:/api/v1/host/BiHub/{tenantId}
   * @secure
   */
  export namespace V1HostBiHubDetail {
    export type PathParams = {
      tenantId: number;
    };
    export const BaseRequest = {
      url: "/host/BiHub/{tenantId}",
      method: "GET",
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = BiHubPipelineDtoResult;
  }

  /**
   * No description     * @tags BiHub
   * @name V1HostBiHubUpdate
   * @request PUT:/api/v1/host/BiHub
   * @secure
   */
  export namespace V1HostBiHubUpdate {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/host/BiHub",
      method: "PUT",
    };
    export type RequestQuery = {};
    export type RequestBody = UpdateBiHubPipelineRequestDto;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description     * @tags BiHub
   * @name V1HostBiHubRunCreate
   * @request POST:/api/v1/host/BiHub/Run
   * @secure
   */
  export namespace V1HostBiHubRunCreate {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/host/BiHub/Run",
      method: "POST",
    };
    export type RequestQuery = {};
    export type RequestBody = RunBiHubPipelineFromHostRequestDto;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description     * @tags BiHub
   * @name V1HostBiHubLogsList
   * @request GET:/api/v1/host/BiHub/Logs
   * @secure
   */
  export namespace V1HostBiHubLogsList {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/host/BiHub/Logs",
      method: "GET",
    };
    export type RequestQuery = {
      /** @format int32 */
      TenantId?: number;
      Sorting?: string;
      /**
       * @format int32
       * @min 0
       * @max 2147483647
       */
      SkipCount?: number;
      /**
       * @format int32
       * @min 1
       * @max 2147483647
       */
      MaxResultCount?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = BiHubPipelineRunLogDtoArrayResult;
  }

  /**
   * No description     * @tags BiHub
   * @name V1HostBiHubLogsPreviewLogDetail
   * @request GET:/api/v1/host/BiHub/Logs/PreviewLog/{id}
   * @secure
   */
  export namespace V1HostBiHubLogsPreviewLogDetail {
    export type PathParams = {
      id: number;
    };
    export const BaseRequest = {
      url: "/host/BiHub/Logs/PreviewLog/{id}",
      method: "GET",
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = FileContentResultResult;
  }

  /**
   * No description     * @tags BiHub
   * @name V1HostBiHubBiHubScheduledCreate
   * @request POST:/api/v1/host/BiHub/BiHubScheduled
   * @secure
   */
  export namespace V1HostBiHubBiHubScheduledCreate {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/host/BiHub/BiHubScheduled",
      method: "POST",
    };
    export type RequestQuery = {};
    export type RequestBody = UpdateBiHubScheduledJobDto;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description     * @tags BiHub
   * @name V1HostBiHubBiHubScheduledDelete
   * @request DELETE:/api/v1/host/BiHub/BiHubScheduled/{jobId}
   * @secure
   */
  export namespace V1HostBiHubBiHubScheduledDelete {
    export type PathParams = {
      jobId: number;
    };
    export const BaseRequest = {
      url: "/host/BiHub/BiHubScheduled/{jobId}",
      method: "DELETE",
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description     * @tags BiHub
   * @name V1HostBiHubBiHubScheduledDetail
   * @request GET:/api/v1/host/BiHub/BiHubScheduled/{jobId}
   * @secure
   */
  export namespace V1HostBiHubBiHubScheduledDetail {
    export type PathParams = {
      jobId: number;
    };
    export const BaseRequest = {
      url: "/host/BiHub/BiHubScheduled/{jobId}",
      method: "GET",
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = BiHubScheduledDetailsDto;
  }

  /**
   * No description     * @tags BiHub
   * @name V1HostBiHubBiHubScheduledPagedList
   * @request GET:/api/v1/host/BiHub/BiHubScheduled/Paged
   * @secure
   */
  export namespace V1HostBiHubBiHubScheduledPagedList {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/host/BiHub/BiHubScheduled/Paged",
      method: "GET",
    };
    export type RequestQuery = {
      Keyword?: string;
      Sorting?: string;
      IsActive?: boolean;
      /**
       * @format int32
       * @min 0
       * @max 2147483647
       */
      SkipCount?: number;
      /**
       * @format int32
       * @min 1
       * @max 2147483647
       */
      MaxResultCount?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = BiHubScheduledLogDtoPagedResultWithFiltersDtoResult;
  }

  /**
   * No description     * @tags BiHub
   * @name V1HostBiHubBiHubScheduledAvailablePipelinesList
   * @request GET:/api/v1/host/BiHub/BiHubScheduled/AvailablePipelines
   * @secure
   */
  export namespace V1HostBiHubBiHubScheduledAvailablePipelinesList {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/host/BiHub/BiHubScheduled/AvailablePipelines",
      method: "GET",
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = OptionValueLabelResult;
  }

  /**
   * No description     * @tags BiHub
   * @name V1BiHubUpdatePipelineRunStartedCreate
   * @request POST:/api/v1/BiHub/UpdatePipelineRunStarted
   * @secure
   */
  export namespace V1BiHubUpdatePipelineRunStartedCreate {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/BiHub/UpdatePipelineRunStarted",
      method: "POST",
    };
    export type RequestQuery = {};
    export type RequestBody = UpdateBiHubPipelineRunStatusRequestDto;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description     * @tags BiHub
   * @name V1BiHubUpdateTaskRunStartedCreate
   * @request POST:/api/v1/BiHub/UpdateTaskRunStarted
   * @secure
   */
  export namespace V1BiHubUpdateTaskRunStartedCreate {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/BiHub/UpdateTaskRunStarted",
      method: "POST",
    };
    export type RequestQuery = {};
    export type RequestBody = UpdateBiHubTaskRunStatusRequestDto;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description     * @tags BiHub
   * @name V1BiHubUpdatePowerBiDatasetRefreshStartedCreate
   * @request POST:/api/v1/BiHub/UpdatePowerBiDatasetRefreshStarted
   * @secure
   */
  export namespace V1BiHubUpdatePowerBiDatasetRefreshStartedCreate {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/BiHub/UpdatePowerBiDatasetRefreshStarted",
      method: "POST",
    };
    export type RequestQuery = {};
    export type RequestBody = UpdateBiHubPowerBiDatasetRefreshRequestDto;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description     * @tags BiHub
   * @name V1BiHubUpdatePipelineRunFinishedCreate
   * @request POST:/api/v1/BiHub/UpdatePipelineRunFinished
   * @secure
   */
  export namespace V1BiHubUpdatePipelineRunFinishedCreate {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/BiHub/UpdatePipelineRunFinished",
      method: "POST",
    };
    export type RequestQuery = {};
    export type RequestBody = UpdateBiHubPipelineRunStatusRequestDto;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description     * @tags BiHub
   * @name V1BiHubUpdateTaskRunFinishedCreate
   * @request POST:/api/v1/BiHub/UpdateTaskRunFinished
   * @secure
   */
  export namespace V1BiHubUpdateTaskRunFinishedCreate {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/BiHub/UpdateTaskRunFinished",
      method: "POST",
    };
    export type RequestQuery = {};
    export type RequestBody = UpdateBiHubTaskRunStatusRequestDto;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description     * @tags BiHub
   * @name V1BiHubUpdatePowerBiDatasetRefreshFinishedCreate
   * @request POST:/api/v1/BiHub/UpdatePowerBiDatasetRefreshFinished
   * @secure
   */
  export namespace V1BiHubUpdatePowerBiDatasetRefreshFinishedCreate {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/BiHub/UpdatePowerBiDatasetRefreshFinished",
      method: "POST",
    };
    export type RequestQuery = {};
    export type RequestBody = UpdateBiHubPowerBiDatasetRefreshRequestDto;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description     * @tags DimMapping
   * @name V1TenantDimMappingGetMissingMappingsList
   * @request GET:/api/v1/tenant/DimMapping/GetMissingMappings
   * @secure
   */
  export namespace V1TenantDimMappingGetMissingMappingsList {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/tenant/DimMapping/GetMissingMappings",
      method: "GET",
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = MappingErrorDtoListResult;
  }

  /**
   * No description     * @tags DimMapping
   * @name V1TenantDimMappingList
   * @request GET:/api/v1/tenant/DimMapping
   * @secure
   */
  export namespace V1TenantDimMappingList {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/tenant/DimMapping",
      method: "GET",
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = DimTableDtoResult;
  }

  /**
   * No description     * @tags DimMapping
   * @name V1TenantDimMappingUpdate
   * @request PUT:/api/v1/tenant/DimMapping
   * @secure
   */
  export namespace V1TenantDimMappingUpdate {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/tenant/DimMapping",
      method: "PUT",
    };
    export type RequestQuery = {};
    export type RequestBody = UpdateDimTableTenantRequestDto;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description     * @tags DimMapping
   * @name V1TenantDimMappingExecuteMappingStoredProcedureList
   * @request GET:/api/v1/tenant/DimMapping/ExecuteMappingStoredProcedure
   * @secure
   */
  export namespace V1TenantDimMappingExecuteMappingStoredProcedureList {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/tenant/DimMapping/ExecuteMappingStoredProcedure",
      method: "GET",
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = StringListResult;
  }

  /**
   * No description     * @tags DimMapping
   * @name V1HostDimMappingGetAvailableDimTablesDetail
   * @request GET:/api/v1/host/DimMapping/GetAvailableDimTables/{tenantId}
   * @secure
   */
  export namespace V1HostDimMappingGetAvailableDimTablesDetail {
    export type PathParams = {
      tenantId: number;
    };
    export const BaseRequest = {
      url: "/host/DimMapping/GetAvailableDimTables/{tenantId}",
      method: "GET",
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = StringListResult;
  }

  /**
   * No description     * @tags DimMapping
   * @name V1HostDimMappingExecuteMappingStoredProcedureDetail
   * @request GET:/api/v1/host/DimMapping/ExecuteMappingStoredProcedure/{tenantId}
   * @secure
   */
  export namespace V1HostDimMappingExecuteMappingStoredProcedureDetail {
    export type PathParams = {
      tenantId: number;
    };
    export const BaseRequest = {
      url: "/host/DimMapping/ExecuteMappingStoredProcedure/{tenantId}",
      method: "GET",
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = StringListResult;
  }

  /**
   * No description     * @tags DimMapping
   * @name V1HostDimMappingGetMissingMappingsList
   * @request GET:/api/v1/host/DimMapping/GetMissingMappings
   * @secure
   */
  export namespace V1HostDimMappingGetMissingMappingsList {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/host/DimMapping/GetMissingMappings",
      method: "GET",
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = MappingErrorDtoListResult;
  }

  /**
   * No description     * @tags DimMapping
   * @name V1HostDimMappingList
   * @request GET:/api/v1/host/DimMapping
   * @secure
   */
  export namespace V1HostDimMappingList {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/host/DimMapping",
      method: "GET",
    };
    export type RequestQuery = {
      /** @format int32 */
      TenantId?: number;
      DimMappingTable?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = DimTableDtoResult;
  }

  /**
   * No description     * @tags DimMapping
   * @name V1HostDimMappingUpdate
   * @request PUT:/api/v1/host/DimMapping
   * @secure
   */
  export namespace V1HostDimMappingUpdate {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/host/DimMapping",
      method: "PUT",
    };
    export type RequestQuery = {};
    export type RequestBody = UpdateDimTableRequestDto;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description     * @tags DocumentCabinet
   * @name V1TenantDocumentCabinetMoveFileOrFolderCreate
   * @request POST:/api/v1/tenant/DocumentCabinet/MoveFileOrFolder
   * @secure
   */
  export namespace V1TenantDocumentCabinetMoveFileOrFolderCreate {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/tenant/DocumentCabinet/MoveFileOrFolder",
      method: "POST",
    };
    export type RequestQuery = {
      /** @format int32 */
      MovingEntityId?: number;
      /** @format int32 */
      TargetFolderId?: number;
      /** 1 = File, 2 = Folder */
      FileOrFolder?: MoveFileOrFolderType;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description     * @tags DocumentCabinet
   * @name V1TenantDocumentCabinetRecoverFoldersAndContentsUpdate
   * @request PUT:/api/v1/tenant/DocumentCabinet/RecoverFoldersAndContents/{folderId}
   * @secure
   */
  export namespace V1TenantDocumentCabinetRecoverFoldersAndContentsUpdate {
    export type PathParams = {
      folderId: number;
    };
    export const BaseRequest = {
      url: "/tenant/DocumentCabinet/RecoverFoldersAndContents/{folderId}",
      method: "PUT",
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description     * @tags DocumentCabinet
   * @name V1TenantDocumentCabinetBreadCrumbsList
   * @request GET:/api/v1/tenant/DocumentCabinet/BreadCrumbs
   * @secure
   */
  export namespace V1TenantDocumentCabinetBreadCrumbsList {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/tenant/DocumentCabinet/BreadCrumbs",
      method: "GET",
    };
    export type RequestQuery = {
      /** @format int32 */
      currentFolderId?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = BreadCrumbDtoListResult;
  }

  /**
   * No description     * @tags DocumentCabinet
   * @name V1TenantDocumentCabinetFolderCreate
   * @request POST:/api/v1/tenant/DocumentCabinet/Folder
   * @secure
   */
  export namespace V1TenantDocumentCabinetFolderCreate {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/tenant/DocumentCabinet/Folder",
      method: "POST",
    };
    export type RequestQuery = {};
    export type RequestBody = CreateFolderRequestDto;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description     * @tags DocumentCabinet
   * @name V1TenantDocumentCabinetFolderUpdate
   * @request PUT:/api/v1/tenant/DocumentCabinet/Folder
   * @secure
   */
  export namespace V1TenantDocumentCabinetFolderUpdate {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/tenant/DocumentCabinet/Folder",
      method: "PUT",
    };
    export type RequestQuery = {};
    export type RequestBody = UpdateFolderRequestDto;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description     * @tags DocumentCabinet
   * @name V1TenantDocumentCabinetFolderDelete
   * @request DELETE:/api/v1/tenant/DocumentCabinet/Folder/{id}
   * @secure
   */
  export namespace V1TenantDocumentCabinetFolderDelete {
    export type PathParams = {
      id: number;
    };
    export const BaseRequest = {
      url: "/tenant/DocumentCabinet/Folder/{id}",
      method: "DELETE",
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description     * @tags DocumentCabinet
   * @name V1TenantDocumentCabinetFolderContentsList
   * @request GET:/api/v1/tenant/DocumentCabinet/Folder/Contents
   * @secure
   */
  export namespace V1TenantDocumentCabinetFolderContentsList {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/tenant/DocumentCabinet/Folder/Contents",
      method: "GET",
    };
    export type RequestQuery = {
      /** @format int32 */
      FolderId?: number;
      InTrashcan?: boolean;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = FolderContentsDtoResult;
  }

  /**
   * No description     * @tags DocumentCabinet
   * @name V1TenantDocumentCabinetFolderDetailsDetail
   * @request GET:/api/v1/tenant/DocumentCabinet/Folder/Details/{id}
   * @secure
   */
  export namespace V1TenantDocumentCabinetFolderDetailsDetail {
    export type PathParams = {
      id: number;
    };
    export const BaseRequest = {
      url: "/tenant/DocumentCabinet/Folder/Details/{id}",
      method: "GET",
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = FolderDtoResult;
  }

  /**
   * No description     * @tags DocumentCabinet
   * @name V1TenantDocumentCabinetFileUploadCreate
   * @request POST:/api/v1/tenant/DocumentCabinet/File/Upload
   * @secure
   */
  export namespace V1TenantDocumentCabinetFileUploadCreate {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/tenant/DocumentCabinet/File/Upload",
      method: "POST",
    };
    export type RequestQuery = {};
    export type RequestBody = {
      Name?: string;
      /** @format int32 */
      DocumentCabinetFolderId?: number;
      /** @format binary */
      FileUpload?: File;
    };
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description     * @tags DocumentCabinet
   * @name V1TenantDocumentCabinetFileUploadAuditfileCreate
   * @request POST:/api/v1/tenant/DocumentCabinet/File/UploadAuditfile
   * @secure
   */
  export namespace V1TenantDocumentCabinetFileUploadAuditfileCreate {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/tenant/DocumentCabinet/File/UploadAuditfile",
      method: "POST",
    };
    export type RequestQuery = {};
    export type RequestBody = {
      Name?: string;
      /** @format int32 */
      DocumentCabinetFolderId?: number;
      /** @format binary */
      FileUpload?: File;
    };
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description     * @tags DocumentCabinet
   * @name V1TenantDocumentCabinetFileDownloadDetail
   * @request GET:/api/v1/tenant/DocumentCabinet/File/Download/{id}
   * @secure
   */
  export namespace V1TenantDocumentCabinetFileDownloadDetail {
    export type PathParams = {
      id: number;
    };
    export const BaseRequest = {
      url: "/tenant/DocumentCabinet/File/Download/{id}",
      method: "GET",
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = File;
  }

  /**
   * No description     * @tags DocumentCabinet
   * @name V1TenantDocumentCabinetFilePreviewDetail
   * @request GET:/api/v1/tenant/DocumentCabinet/File/Preview/{id}
   * @secure
   */
  export namespace V1TenantDocumentCabinetFilePreviewDetail {
    export type PathParams = {
      id: number;
    };
    export const BaseRequest = {
      url: "/tenant/DocumentCabinet/File/Preview/{id}",
      method: "GET",
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = FilePreviewDtoResult;
  }

  /**
   * No description     * @tags DocumentCabinet
   * @name V1TenantDocumentCabinetFileDetailsDetail
   * @request GET:/api/v1/tenant/DocumentCabinet/File/Details/{id}
   * @secure
   */
  export namespace V1TenantDocumentCabinetFileDetailsDetail {
    export type PathParams = {
      id: number;
    };
    export const BaseRequest = {
      url: "/tenant/DocumentCabinet/File/Details/{id}",
      method: "GET",
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = FileDetailsDtoResult;
  }

  /**
   * No description     * @tags DocumentCabinet
   * @name V1TenantDocumentCabinetFileDetailsUpdate
   * @request PUT:/api/v1/tenant/DocumentCabinet/File/Details
   * @secure
   */
  export namespace V1TenantDocumentCabinetFileDetailsUpdate {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/tenant/DocumentCabinet/File/Details",
      method: "PUT",
    };
    export type RequestQuery = {};
    export type RequestBody = UpdateFileDetailsDto;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description     * @tags DocumentCabinet
   * @name V1TenantDocumentCabinetFileDelete
   * @request DELETE:/api/v1/tenant/DocumentCabinet/File/{fileId}
   * @secure
   */
  export namespace V1TenantDocumentCabinetFileDelete {
    export type PathParams = {
      fileId: number;
    };
    export const BaseRequest = {
      url: "/tenant/DocumentCabinet/File/{fileId}",
      method: "DELETE",
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description     * @tags Edition
   * @name V1HostEditionList
   * @request GET:/api/v1/host/Edition
   * @secure
   */
  export namespace V1HostEditionList {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/host/Edition",
      method: "GET",
    };
    export type RequestQuery = {
      /** @format int32 */
      id?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = GetEditionDtoResult;
  }

  /**
   * No description     * @tags Edition
   * @name V1HostEditionCreate
   * @request POST:/api/v1/host/Edition
   * @secure
   */
  export namespace V1HostEditionCreate {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/host/Edition",
      method: "POST",
    };
    export type RequestQuery = {};
    export type RequestBody = CreateEditionRequestDto;
    export type RequestHeaders = {};
    export type ResponseBody = Int32Result;
  }

  /**
   * No description     * @tags Edition
   * @name V1HostEditionUpdate
   * @request PUT:/api/v1/host/Edition
   * @secure
   */
  export namespace V1HostEditionUpdate {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/host/Edition",
      method: "PUT",
    };
    export type RequestQuery = {};
    export type RequestBody = UpdateEditionRequestDto;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description     * @tags Edition
   * @name V1HostEditionPagedList
   * @request GET:/api/v1/host/Edition/Paged
   * @secure
   */
  export namespace V1HostEditionPagedList {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/host/Edition/Paged",
      method: "GET",
    };
    export type RequestQuery = {
      Keyword?: string;
      Sorting?: string;
      /**
       * @format int32
       * @min 0
       * @max 2147483647
       */
      SkipCount?: number;
      /**
       * @format int32
       * @min 1
       * @max 2147483647
       */
      MaxResultCount?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = EditionPagedResultDtoResult;
  }

  /**
   * No description     * @tags Edition
   * @name V1HostEditionDelete
   * @request DELETE:/api/v1/host/Edition/{id}
   * @secure
   */
  export namespace V1HostEditionDelete {
    export type PathParams = {
      id: number;
    };
    export const BaseRequest = {
      url: "/host/Edition/{id}",
      method: "DELETE",
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description     * @tags ImportForms
   * @name V1TenantImportFormsDetail
   * @request GET:/api/v1/tenant/ImportForms/{id}
   * @secure
   */
  export namespace V1TenantImportFormsDetail {
    export type PathParams = {
      id: number;
    };
    export const BaseRequest = {
      url: "/tenant/ImportForms/{id}",
      method: "GET",
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = ImportFormDtoResult;
  }

  /**
   * No description     * @tags ImportForms
   * @name V1TenantImportFormsDataUpdate
   * @request PUT:/api/v1/tenant/ImportForms/Data
   * @secure
   */
  export namespace V1TenantImportFormsDataUpdate {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/tenant/ImportForms/Data",
      method: "PUT",
    };
    export type RequestQuery = {};
    export type RequestBody = UpdateImportFormDataRequestDto;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description     * @tags ImportForms
   * @name V1TenantImportFormsDataNewUpdate
   * @request PUT:/api/v1/tenant/ImportForms/Data_NEW
   * @secure
   */
  export namespace V1TenantImportFormsDataNewUpdate {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/tenant/ImportForms/Data_NEW",
      method: "PUT",
    };
    export type RequestQuery = {};
    export type RequestBody = UpdateImportFormDataRequestDto;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description     * @tags ImportForms
   * @name V1HostImportFormsDetail
   * @request GET:/api/v1/host/ImportForms/{id}
   * @secure
   */
  export namespace V1HostImportFormsDetail {
    export type PathParams = {
      id: number;
    };
    export const BaseRequest = {
      url: "/host/ImportForms/{id}",
      method: "GET",
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = UpdateImportFormPropertiesRequestDtoResult;
  }

  /**
   * No description     * @tags ImportForms
   * @name V1HostImportFormsDelete
   * @request DELETE:/api/v1/host/ImportForms/{id}
   * @secure
   */
  export namespace V1HostImportFormsDelete {
    export type PathParams = {
      id: number;
    };
    export const BaseRequest = {
      url: "/host/ImportForms/{id}",
      method: "DELETE",
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description     * @tags ImportForms
   * @name V1HostImportFormsCreate
   * @request POST:/api/v1/host/ImportForms
   * @secure
   */
  export namespace V1HostImportFormsCreate {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/host/ImportForms",
      method: "POST",
    };
    export type RequestQuery = {};
    export type RequestBody = CreateImportFormRequestDto;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description     * @tags ImportForms
   * @name V1HostImportFormsUpdate
   * @request PUT:/api/v1/host/ImportForms
   * @secure
   */
  export namespace V1HostImportFormsUpdate {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/host/ImportForms",
      method: "PUT",
    };
    export type RequestQuery = {};
    export type RequestBody = UpdateImportFormPropertiesRequestDto;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description     * @tags Logs
   * @name V1HostLogsImportFormSqlLogsPagedList
   * @request GET:/api/v1/host/Logs/ImportFormSqlLogs/Paged
   * @secure
   */
  export namespace V1HostLogsImportFormSqlLogsPagedList {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/host/Logs/ImportFormSqlLogs/Paged",
      method: "GET",
    };
    export type RequestQuery = {
      Keyword?: string;
      Sorting?: string;
      IsActive?: boolean;
      /**
       * @format int32
       * @min 0
       * @max 2147483647
       */
      SkipCount?: number;
      /**
       * @format int32
       * @min 1
       * @max 2147483647
       */
      MaxResultCount?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = ImportFormSqlLogDtoPagedResultDtoResult;
  }

  /**
   * No description     * @tags Logs
   * @name V1HostLogsDocumentCabinetLogsPagedList
   * @request GET:/api/v1/host/Logs/DocumentCabinetLogs/Paged
   * @secure
   */
  export namespace V1HostLogsDocumentCabinetLogsPagedList {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/host/Logs/DocumentCabinetLogs/Paged",
      method: "GET",
    };
    export type RequestQuery = {
      Keyword?: string;
      Sorting?: string;
      IsActive?: boolean;
      /**
       * @format int32
       * @min 0
       * @max 2147483647
       */
      SkipCount?: number;
      /**
       * @format int32
       * @min 1
       * @max 2147483647
       */
      MaxResultCount?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = DocumentCabinetLogDtoPagedResultDtoResult;
  }

  /**
   * No description     * @tags Logs
   * @name V1HostLogsDimChangeLogsPagedList
   * @request GET:/api/v1/host/Logs/DimChangeLogs/Paged
   * @secure
   */
  export namespace V1HostLogsDimChangeLogsPagedList {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/host/Logs/DimChangeLogs/Paged",
      method: "GET",
    };
    export type RequestQuery = {
      Keyword?: string;
      TenantName?: string[];
      Action?: string[];
      Sorting?: string;
      IsActive?: boolean;
      /**
       * @format int32
       * @min 0
       * @max 2147483647
       */
      SkipCount?: number;
      /**
       * @format int32
       * @min 1
       * @max 2147483647
       */
      MaxResultCount?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = DimMappingChangeLogDtoPagedResultWithFiltersDtoResult;
  }

  /**
   * No description     * @tags Logs
   * @name V1HostLogsHangFireJobLogsPagedList
   * @request GET:/api/v1/host/Logs/HangFireJobLogs/Paged
   * @secure
   */
  export namespace V1HostLogsHangFireJobLogsPagedList {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/host/Logs/HangFireJobLogs/Paged",
      method: "GET",
    };
    export type RequestQuery = {
      Keyword?: string;
      Sorting?: string;
      IsActive?: boolean;
      /**
       * @format int32
       * @min 0
       * @max 2147483647
       */
      SkipCount?: number;
      /**
       * @format int32
       * @min 1
       * @max 2147483647
       */
      MaxResultCount?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = HangFireJobDtoPagedResultDtoResult;
  }

  /**
   * No description     * @tags Logs
   * @name V1HostLogsReportEmbedLogsList
   * @request GET:/api/v1/host/Logs/ReportEmbedLogs
   * @secure
   */
  export namespace V1HostLogsReportEmbedLogsList {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/host/Logs/ReportEmbedLogs",
      method: "GET",
    };
    export type RequestQuery = {
      /** @format int32 */
      TenantId: number;
      IncludeInternalEmails?: boolean;
      /** @format date-time */
      StartDate?: string;
      /** @format date-time */
      EndDate?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = ReportEmbedDetailsDtoResult;
  }

  /**
   * No description     * @tags MasterUsers
   * @name V1MasterUsersFavoriteTenantUpdate
   * @request PUT:/api/v1/MasterUsers/FavoriteTenant
   * @secure
   */
  export namespace V1MasterUsersFavoriteTenantUpdate {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/MasterUsers/FavoriteTenant",
      method: "PUT",
    };
    export type RequestQuery = {};
    export type RequestBody = UpdateMasterUserFavoriteTenantDto;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description     * @tags MasterUsers
   * @name V1MasterUsersResetpasswordCreate
   * @request POST:/api/v1/MasterUsers/resetpassword
   * @secure
   */
  export namespace V1MasterUsersResetpasswordCreate {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/MasterUsers/resetpassword",
      method: "POST",
    };
    export type RequestQuery = {};
    export type RequestBody = PasswordResetDto;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description     * @tags MasterUsers
   * @name V1MasterUsersChangepasswordCreate
   * @request POST:/api/v1/MasterUsers/changepassword
   * @secure
   */
  export namespace V1MasterUsersChangepasswordCreate {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/MasterUsers/changepassword",
      method: "POST",
    };
    export type RequestQuery = {};
    export type RequestBody = ChangePasswordDto;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description     * @tags MasterUsers
   * @name V1MasterUsersResetpasswordDetail
   * @request GET:/api/v1/MasterUsers/resetpassword/{email}
   * @secure
   */
  export namespace V1MasterUsersResetpasswordDetail {
    export type PathParams = {
      email: string;
    };
    export const BaseRequest = {
      url: "/MasterUsers/resetpassword/{email}",
      method: "GET",
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description     * @tags MasterUsers
   * @name V1MasterUsersSettingsList
   * @request GET:/api/v1/MasterUsers/settings
   * @secure
   */
  export namespace V1MasterUsersSettingsList {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/MasterUsers/settings",
      method: "GET",
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = MasterUserSettingsDto;
  }

  /**
   * No description     * @tags MasterUsers
   * @name V1MasterUsersSettingsUpdate
   * @request PUT:/api/v1/MasterUsers/settings
   * @secure
   */
  export namespace V1MasterUsersSettingsUpdate {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/MasterUsers/settings",
      method: "PUT",
    };
    export type RequestQuery = {};
    export type RequestBody = UpdateMasterUserSettingsDto;
    export type RequestHeaders = {};
    export type ResponseBody = MasterUserSettingsDto;
  }

  /**
   * No description     * @tags MasterUsers
   * @name V1HostMasterUsersPagedList
   * @request GET:/api/v1/host/MasterUsers/Paged
   * @secure
   */
  export namespace V1HostMasterUsersPagedList {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/host/MasterUsers/Paged",
      method: "GET",
    };
    export type RequestQuery = {
      Keyword?: string;
      Sorting?: string;
      /**
       * @format int32
       * @min 0
       * @max 2147483647
       */
      SkipCount?: number;
      /**
       * @format int32
       * @min 1
       * @max 2147483647
       */
      MaxResultCount?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = MasterUserDtoPagedResultDtoResult;
  }

  /**
   * No description     * @tags MasterUsers
   * @name V1HostMasterUsersResetpasswordDetail
   * @request GET:/api/v1/host/MasterUsers/resetpassword/{masterUserId}
   * @secure
   */
  export namespace V1HostMasterUsersResetpasswordDetail {
    export type PathParams = {
      masterUserId: number;
    };
    export const BaseRequest = {
      url: "/host/MasterUsers/resetpassword/{masterUserId}",
      method: "GET",
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description     * @tags MasterUsers
   * @name V1HostMasterUsersDelete
   * @request DELETE:/api/v1/host/MasterUsers/{masterUserId}
   * @secure
   */
  export namespace V1HostMasterUsersDelete {
    export type PathParams = {
      masterUserId: number;
    };
    export const BaseRequest = {
      url: "/host/MasterUsers/{masterUserId}",
      method: "DELETE",
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description     * @tags PowerBi
   * @name V1HostPowerBiCreate
   * @request POST:/api/v1/host/PowerBi
   * @secure
   */
  export namespace V1HostPowerBiCreate {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/host/PowerBi",
      method: "POST",
    };
    export type RequestQuery = {};
    export type RequestBody = CreatePowerBiReportRequestDto;
    export type RequestHeaders = {};
    export type ResponseBody = Int32Result;
  }

  /**
   * No description     * @tags PowerBi
   * @name V1HostPowerBiUpdate
   * @request PUT:/api/v1/host/PowerBi
   * @secure
   */
  export namespace V1HostPowerBiUpdate {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/host/PowerBi",
      method: "PUT",
    };
    export type RequestQuery = {};
    export type RequestBody = UpdatePowerBiReportRequestDto;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description     * @tags PowerBi
   * @name V1HostPowerBiPaginatedReportCreate
   * @request POST:/api/v1/host/PowerBi/PaginatedReport
   * @secure
   */
  export namespace V1HostPowerBiPaginatedReportCreate {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/host/PowerBi/PaginatedReport",
      method: "POST",
    };
    export type RequestQuery = {};
    export type RequestBody = CreatePaginatedReportRequestDto;
    export type RequestHeaders = {};
    export type ResponseBody = Int32Result;
  }

  /**
   * No description     * @tags PowerBi
   * @name V1HostPowerBiPaginatedReportUpdate
   * @request PUT:/api/v1/host/PowerBi/PaginatedReport
   * @secure
   */
  export namespace V1HostPowerBiPaginatedReportUpdate {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/host/PowerBi/PaginatedReport",
      method: "PUT",
    };
    export type RequestQuery = {};
    export type RequestBody = UpdatePaginatedReportRequestDto;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description     * @tags PowerBi
   * @name V1HostPowerBiEditableDetail
   * @request GET:/api/v1/host/PowerBi/Editable/{id}
   * @secure
   */
  export namespace V1HostPowerBiEditableDetail {
    export type PathParams = {
      id: number;
    };
    export const BaseRequest = {
      url: "/host/PowerBi/Editable/{id}",
      method: "GET",
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PowerBiReportDtoResult;
  }

  /**
   * No description     * @tags PowerBi
   * @name V1HostPowerBiEditablePaginatedReportDetail
   * @request GET:/api/v1/host/PowerBi/Editable/PaginatedReport/{id}
   * @secure
   */
  export namespace V1HostPowerBiEditablePaginatedReportDetail {
    export type PathParams = {
      id: number;
    };
    export const BaseRequest = {
      url: "/host/PowerBi/Editable/PaginatedReport/{id}",
      method: "GET",
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PaginatedReportDtoResult;
  }

  /**
   * No description     * @tags PowerBi
   * @name V1HostPowerBiMigrateReportsCreate
   * @request POST:/api/v1/host/PowerBi/MigrateReports
   * @secure
   */
  export namespace V1HostPowerBiMigrateReportsCreate {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/host/PowerBi/MigrateReports",
      method: "POST",
    };
    export type RequestQuery = {};
    export type RequestBody = MigatePowerBiReportRequestDto;
    export type RequestHeaders = {};
    export type ResponseBody = ReportMigrationViewModelResult;
  }

  /**
   * No description     * @tags PowerBi
   * @name V1HostPowerBiDelete
   * @request DELETE:/api/v1/host/PowerBi/{id}
   * @secure
   */
  export namespace V1HostPowerBiDelete {
    export type PathParams = {
      id: number;
    };
    export const BaseRequest = {
      url: "/host/PowerBi/{id}",
      method: "DELETE",
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description     * @tags PowerBi
   * @name V1HostPowerBiPaginatedReportDelete
   * @request DELETE:/api/v1/host/PowerBi/PaginatedReport/{id}
   * @secure
   */
  export namespace V1HostPowerBiPaginatedReportDelete {
    export type PathParams = {
      id: number;
    };
    export const BaseRequest = {
      url: "/host/PowerBi/PaginatedReport/{id}",
      method: "DELETE",
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description     * @tags PowerBi
   * @name V1HostPowerBiDropdownDetail
   * @request GET:/api/v1/host/PowerBi/Dropdown/{tenantId}
   * @secure
   */
  export namespace V1HostPowerBiDropdownDetail {
    export type PathParams = {
      tenantId: number;
    };
    export const BaseRequest = {
      url: "/host/PowerBi/Dropdown/{tenantId}",
      method: "GET",
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description     * @tags PowerBiApi
   * @name V1TenantPowerBiApiEmbedDetail
   * @request GET:/api/v1/tenant/PowerBiApi/Embed/{reportId}
   * @secure
   */
  export namespace V1TenantPowerBiApiEmbedDetail {
    export type PathParams = {
      reportId: number;
    };
    export const BaseRequest = {
      url: "/tenant/PowerBiApi/Embed/{reportId}",
      method: "GET",
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = EmbedConfigResult;
  }

  /**
   * No description     * @tags PowerBiApi
   * @name V1TenantPowerBiApiEmbedPaginatedReportDetail
   * @request GET:/api/v1/tenant/PowerBiApi/Embed/paginatedReport/{reportId}
   * @secure
   */
  export namespace V1TenantPowerBiApiEmbedPaginatedReportDetail {
    export type PathParams = {
      reportId: number;
    };
    export const BaseRequest = {
      url: "/tenant/PowerBiApi/Embed/paginatedReport/{reportId}",
      method: "GET",
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = EmbedConfigResult;
  }

  /**
   * No description     * @tags PowerBiApi
   * @name V1HostPowerBiApiTenantUtilizationList
   * @request GET:/api/v1/host/PowerBiApi/TenantUtilization
   * @secure
   */
  export namespace V1HostPowerBiApiTenantUtilizationList {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/host/PowerBiApi/TenantUtilization",
      method: "GET",
    };
    export type RequestQuery = {
      Keyword?: string;
      Sorting?: string;
      /**
       * @format int32
       * @min 0
       * @max 2147483647
       */
      SkipCount?: number;
      /**
       * @format int32
       * @min 1
       * @max 2147483647
       */
      MaxResultCount?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PowerBiUtilizationDtoPagedResultDtoResult;
  }

  /**
   * No description     * @tags PowerBiApi
   * @name V1HostPowerBiApiDetail
   * @request GET:/api/v1/host/PowerBiApi/{tenantId}
   * @secure
   */
  export namespace V1HostPowerBiApiDetail {
    export type PathParams = {
      tenantId: number;
    };
    export const BaseRequest = {
      url: "/host/PowerBiApi/{tenantId}",
      method: "GET",
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PowerBiReportApiInfoListDtoResult;
  }

  /**
   * No description     * @tags PowerBiApi
   * @name V1HostPowerBiApiPaginatedReportDetail
   * @request GET:/api/v1/host/PowerBiApi/paginatedReport/{tenantId}
   * @secure
   */
  export namespace V1HostPowerBiApiPaginatedReportDetail {
    export type PathParams = {
      tenantId: number;
    };
    export const BaseRequest = {
      url: "/host/PowerBiApi/paginatedReport/{tenantId}",
      method: "GET",
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PowerBiReportApiInfoListDtoResult;
  }

  /**
   * No description     * @tags PowerBiApi
   * @name V1HostPowerBiApiProfilesList
   * @request GET:/api/v1/host/PowerBiApi/profiles
   * @secure
   */
  export namespace V1HostPowerBiApiProfilesList {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/host/PowerBiApi/profiles",
      method: "GET",
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PowerBiReportApiInfoListDtoResult;
  }

  /**
   * No description     * @tags PowerBiApi
   * @name V1HostPowerBiApiDeleteDetail
   * @request GET:/api/v1/host/PowerBiApi/delete/{profileId}
   * @secure
   */
  export namespace V1HostPowerBiApiDeleteDetail {
    export type PathParams = {
      profileId: string;
    };
    export const BaseRequest = {
      url: "/host/PowerBiApi/delete/{profileId}",
      method: "GET",
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PowerBiReportApiInfoListDtoResult;
  }

  /**
   * No description     * @tags Roles
   * @name V1CommonRolesList
   * @request GET:/api/v1/common/Roles
   * @secure
   */
  export namespace V1CommonRolesList {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/common/Roles",
      method: "GET",
    };
    export type RequestQuery = {
      /** @format int32 */
      id?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = RoleDtoResult;
  }

  /**
   * No description     * @tags Roles
   * @name V1CommonRolesCreate
   * @request POST:/api/v1/common/Roles
   * @secure
   */
  export namespace V1CommonRolesCreate {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/common/Roles",
      method: "POST",
    };
    export type RequestQuery = {};
    export type RequestBody = CreateRoleRequestDto;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description     * @tags Roles
   * @name V1CommonRolesUpdate
   * @request PUT:/api/v1/common/Roles
   * @secure
   */
  export namespace V1CommonRolesUpdate {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/common/Roles",
      method: "PUT",
    };
    export type RequestQuery = {};
    export type RequestBody = UpdateRoleRequestDto;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description     * @tags Roles
   * @name V1CommonRolesDelete
   * @request DELETE:/api/v1/common/Roles/{id}
   * @secure
   */
  export namespace V1CommonRolesDelete {
    export type PathParams = {
      id: number;
    };
    export const BaseRequest = {
      url: "/common/Roles/{id}",
      method: "DELETE",
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description     * @tags Roles
   * @name V1CommonRolesPermissionsList
   * @request GET:/api/v1/common/Roles/Permissions
   * @secure
   */
  export namespace V1CommonRolesPermissionsList {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/common/Roles/Permissions",
      method: "GET",
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PermissionDtoListResultDto;
  }

  /**
   * No description     * @tags Roles
   * @name V1CommonRolesDropdownList
   * @request GET:/api/v1/common/Roles/Dropdown
   * @secure
   */
  export namespace V1CommonRolesDropdownList {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/common/Roles/Dropdown",
      method: "GET",
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = DropdownItemDto[];
  }

  /**
   * No description     * @tags Roles
   * @name V1CommonRolesPagedList
   * @request GET:/api/v1/common/Roles/Paged
   * @secure
   */
  export namespace V1CommonRolesPagedList {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/common/Roles/Paged",
      method: "GET",
    };
    export type RequestQuery = {
      Keyword?: string;
      Sorting?: string;
      IsActive?: boolean;
      /**
       * @format int32
       * @min 0
       * @max 2147483647
       */
      SkipCount?: number;
      /**
       * @format int32
       * @min 1
       * @max 2147483647
       */
      MaxResultCount?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = RoleListDtoPagedResultDtoResult;
  }

  /**
   * No description     * @tags Ssrs
   * @name V1SsrsGetEncryptedReportPathDetail
   * @request GET:/api/v1/Ssrs/GetEncryptedReportPath/{id}
   * @secure
   */
  export namespace V1SsrsGetEncryptedReportPathDetail {
    export type PathParams = {
      id: number;
    };
    export const BaseRequest = {
      url: "/Ssrs/GetEncryptedReportPath/{id}",
      method: "GET",
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = SsrsReportEmbedDtoResult;
  }

  /**
   * No description     * @tags Ssrs
   * @name V1HostSsrsCreate
   * @request POST:/api/v1/host/Ssrs
   * @secure
   */
  export namespace V1HostSsrsCreate {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/host/Ssrs",
      method: "POST",
    };
    export type RequestQuery = {};
    export type RequestBody = CreateSsrsReportRequestDto;
    export type RequestHeaders = {};
    export type ResponseBody = Int32Result;
  }

  /**
   * No description     * @tags Ssrs
   * @name V1HostSsrsUpdate
   * @request PUT:/api/v1/host/Ssrs
   * @secure
   */
  export namespace V1HostSsrsUpdate {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/host/Ssrs",
      method: "PUT",
    };
    export type RequestQuery = {};
    export type RequestBody = UpdateSsrsReportRequestDto;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description     * @tags Ssrs
   * @name V1HostSsrsDelete
   * @request DELETE:/api/v1/host/Ssrs/{id}
   * @secure
   */
  export namespace V1HostSsrsDelete {
    export type PathParams = {
      id: number;
    };
    export const BaseRequest = {
      url: "/host/Ssrs/{id}",
      method: "DELETE",
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description     * @tags Ssrs
   * @name V1HostSsrsDetail
   * @request GET:/api/v1/host/Ssrs/{id}
   * @secure
   */
  export namespace V1HostSsrsDetail {
    export type PathParams = {
      id: number;
    };
    export const BaseRequest = {
      url: "/host/Ssrs/{id}",
      method: "GET",
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = StringResult;
  }

  /**
   * No description     * @tags Ssrs
   * @name V1HostSsrsEditableDetail
   * @request GET:/api/v1/host/Ssrs/Editable/{id}
   * @secure
   */
  export namespace V1HostSsrsEditableDetail {
    export type PathParams = {
      id: number;
    };
    export const BaseRequest = {
      url: "/host/Ssrs/Editable/{id}",
      method: "GET",
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = UpdateSsrsReportRequestDtoResult;
  }

  /**
   * No description     * @tags Tenants
   * @name V1HostTenantsCreate
   * @request POST:/api/v1/host/Tenants
   * @secure
   */
  export namespace V1HostTenantsCreate {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/host/Tenants",
      method: "POST",
    };
    export type RequestQuery = {};
    export type RequestBody = CreateTenantRequestDto;
    export type RequestHeaders = {};
    export type ResponseBody = Int32Result;
  }

  /**
   * No description     * @tags Tenants
   * @name V1HostTenantsUpdate
   * @request PUT:/api/v1/host/Tenants
   * @secure
   */
  export namespace V1HostTenantsUpdate {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/host/Tenants",
      method: "PUT",
    };
    export type RequestQuery = {};
    export type RequestBody = UpdateTenantRequestDto;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description     * @tags Tenants
   * @name V1HostTenantsUploadLogoCreate
   * @request POST:/api/v1/host/Tenants/UploadLogo
   * @secure
   */
  export namespace V1HostTenantsUploadLogoCreate {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/host/Tenants/UploadLogo",
      method: "POST",
    };
    export type RequestQuery = {};
    export type RequestBody = {
      /** @format int32 */
      TenantId?: number;
      /** @format binary */
      TenantLogo?: File;
    };
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description     * @tags Tenants
   * @name V1HostTenantsDeleteLogoDelete
   * @request DELETE:/api/v1/host/Tenants/DeleteLogo
   * @secure
   */
  export namespace V1HostTenantsDeleteLogoDelete {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/host/Tenants/DeleteLogo",
      method: "DELETE",
    };
    export type RequestQuery = {
      /** @format int32 */
      tenantId?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description     * @tags Tenants
   * @name V1HostTenantsDetail
   * @request GET:/api/v1/host/Tenants/{id}
   * @secure
   */
  export namespace V1HostTenantsDetail {
    export type PathParams = {
      id: number;
    };
    export const BaseRequest = {
      url: "/host/Tenants/{id}",
      method: "GET",
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = TenantDtoResult;
  }

  /**
   * No description     * @tags Tenants
   * @name V1HostTenantsDelete
   * @request DELETE:/api/v1/host/Tenants/{id}
   * @secure
   */
  export namespace V1HostTenantsDelete {
    export type PathParams = {
      id: number;
    };
    export const BaseRequest = {
      url: "/host/Tenants/{id}",
      method: "DELETE",
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description     * @tags Tenants
   * @name V1HostTenantsPagedList
   * @request GET:/api/v1/host/Tenants/Paged
   * @secure
   */
  export namespace V1HostTenantsPagedList {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/host/Tenants/Paged",
      method: "GET",
    };
    export type RequestQuery = {
      Keyword?: string;
      Sorting?: string;
      EditionName?: string[];
      IsActive?: string[];
      /**
       * @format int32
       * @min 0
       * @max 2147483647
       */
      SkipCount?: number;
      /**
       * @format int32
       * @min 1
       * @max 2147483647
       */
      MaxResultCount?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = DimMappingChangeLogDtoPagedResultWithFiltersDtoResult;
  }

  /**
   * No description     * @tags Tenants
   * @name V1HostTenantsTenantsOptionsList
   * @request GET:/api/v1/host/Tenants/TenantsOptions
   * @secure
   */
  export namespace V1HostTenantsTenantsOptionsList {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/host/Tenants/TenantsOptions",
      method: "GET",
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description     * @tags Tenants
   * @name V1HostTenantsDimColumnOptionsDetail
   * @request GET:/api/v1/host/Tenants/DimColumnOptions/{databaseName}
   * @secure
   */
  export namespace V1HostTenantsDimColumnOptionsDetail {
    export type PathParams = {
      databaseName: string;
    };
    export const BaseRequest = {
      url: "/host/Tenants/DimColumnOptions/{databaseName}",
      method: "GET",
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description     * @tags Tenants
   * @name V1HostTenantsSetTenantStatusCreate
   * @request POST:/api/v1/host/Tenants/SetTenantStatus
   * @secure
   */
  export namespace V1HostTenantsSetTenantStatusCreate {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/host/Tenants/SetTenantStatus",
      method: "POST",
    };
    export type RequestQuery = {};
    export type RequestBody = PatchTenantStatusDto;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description     * @tags Tenants
   * @name V1CommonTenantsLogoList
   * @request GET:/api/v1/common/Tenants/Logo
   * @secure
   */
  export namespace V1CommonTenantsLogoList {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/common/Tenants/Logo",
      method: "GET",
    };
    export type RequestQuery = {
      /** @format int32 */
      tenantId?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description     * @tags UserProfile
   * @name V1CommonUserProfileGoogleAuthenticatorSettingsList
   * @request GET:/api/v1/common/UserProfile/GoogleAuthenticatorSettings
   * @secure
   */
  export namespace V1CommonUserProfileGoogleAuthenticatorSettingsList {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/common/UserProfile/GoogleAuthenticatorSettings",
      method: "GET",
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = GoogleAuthenticatorSettingsDto;
  }

  /**
   * No description     * @tags UserProfile
   * @name V1CommonUserProfileGoogleAuthenticatorSettingsUpdate
   * @request PUT:/api/v1/common/UserProfile/GoogleAuthenticatorSettings
   * @secure
   */
  export namespace V1CommonUserProfileGoogleAuthenticatorSettingsUpdate {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/common/UserProfile/GoogleAuthenticatorSettings",
      method: "PUT",
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = UpdateGoogleAuthenticatorKeyOutput;
  }

  /**
   * No description     * @tags UserProfile
   * @name V1CommonUserProfileGoogleAuthenticatorIsEnabledList
   * @request GET:/api/v1/common/UserProfile/GoogleAuthenticatorIsEnabled
   * @secure
   */
  export namespace V1CommonUserProfileGoogleAuthenticatorIsEnabledList {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/common/UserProfile/GoogleAuthenticatorIsEnabled",
      method: "GET",
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = BooleanResult;
  }

  /**
   * No description     * @tags Users
   * @name V1HostUsersImpersonateTenantUsersList
   * @request GET:/api/v1/host/Users/ImpersonateTenantUsers
   * @secure
   */
  export namespace V1HostUsersImpersonateTenantUsersList {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/host/Users/ImpersonateTenantUsers",
      method: "GET",
    };
    export type RequestQuery = {
      Keyword?: string;
      Sorting?: string;
      /** @format int32 */
      TenantId?: number;
      /**
       * @format int32
       * @min 0
       * @max 2147483647
       */
      SkipCount?: number;
      /**
       * @format int32
       * @min 1
       * @max 2147483647
       */
      MaxResultCount?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description     * @tags Users
   * @name V1CommonUsersCreate
   * @request POST:/api/v1/common/Users
   * @secure
   */
  export namespace V1CommonUsersCreate {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/common/Users",
      method: "POST",
    };
    export type RequestQuery = {};
    export type RequestBody = CreateUserRequestDto;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description     * @tags Users
   * @name V1CommonUsersUpdate
   * @request PUT:/api/v1/common/Users
   * @secure
   */
  export namespace V1CommonUsersUpdate {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/common/Users",
      method: "PUT",
    };
    export type RequestQuery = {};
    export type RequestBody = UpdateUserRequestDto;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description     * @tags Users
   * @name V1CommonUsersDetail
   * @request GET:/api/v1/common/Users/{id}
   * @secure
   */
  export namespace V1CommonUsersDetail {
    export type PathParams = {
      id: number;
    };
    export const BaseRequest = {
      url: "/common/Users/{id}",
      method: "GET",
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = UserDtoResult;
  }

  /**
   * No description     * @tags Users
   * @name V1CommonUsersDelete
   * @request DELETE:/api/v1/common/Users/{id}
   * @secure
   */
  export namespace V1CommonUsersDelete {
    export type PathParams = {
      id: number;
    };
    export const BaseRequest = {
      url: "/common/Users/{id}",
      method: "DELETE",
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description     * @tags Users
   * @name V1CommonUsersGetPermissionsForUserList
   * @request GET:/api/v1/common/Users/GetPermissionsForUser
   * @secure
   */
  export namespace V1CommonUsersGetPermissionsForUserList {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/common/Users/GetPermissionsForUser",
      method: "GET",
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = CurrentUserPermissionsDtoResult;
  }

  /**
   * No description     * @tags Users
   * @name V1CommonUsersPagedList
   * @request GET:/api/v1/common/Users/Paged
   * @secure
   */
  export namespace V1CommonUsersPagedList {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/common/Users/Paged",
      method: "GET",
    };
    export type RequestQuery = {
      Keyword?: string;
      Sorting?: string;
      IsActive?: boolean;
      /**
       * @format int32
       * @min 0
       * @max 2147483647
       */
      SkipCount?: number;
      /**
       * @format int32
       * @min 1
       * @max 2147483647
       */
      MaxResultCount?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = UserDtoPagedResultDtoResult;
  }

  /**
   * No description     * @tags ViewGroups
   * @name V1TenantViewGroupsPowerBiReportViewGroupsList
   * @request GET:/api/v1/tenant/ViewGroups/PowerBiReportViewGroups
   * @secure
   */
  export namespace V1TenantViewGroupsPowerBiReportViewGroupsList {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/tenant/ViewGroups/PowerBiReportViewGroups",
      method: "GET",
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PowerBiReportInfoDtoViewGroupCollectionDtoResult;
  }

  /**
   * No description     * @tags ViewGroups
   * @name V1TenantViewGroupsSsrsReportViewGroupsList
   * @request GET:/api/v1/tenant/ViewGroups/SsrsReportViewGroups
   * @secure
   */
  export namespace V1TenantViewGroupsSsrsReportViewGroupsList {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/tenant/ViewGroups/SsrsReportViewGroups",
      method: "GET",
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = SsrsReportInfoDtoViewGroupCollectionDtoResult;
  }

  /**
   * No description     * @tags ViewGroups
   * @name V1TenantViewGroupsPowerBiPaginatedReportViewGroupsList
   * @request GET:/api/v1/tenant/ViewGroups/PowerBiPaginatedReportViewGroups
   * @secure
   */
  export namespace V1TenantViewGroupsPowerBiPaginatedReportViewGroupsList {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/tenant/ViewGroups/PowerBiPaginatedReportViewGroups",
      method: "GET",
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PaginatedReportInfoDtoViewGroupCollectionDtoResult;
  }

  /**
   * No description     * @tags ViewGroups
   * @name V1TenantViewGroupsImportFormViewGroupsList
   * @request GET:/api/v1/tenant/ViewGroups/ImportFormViewGroups
   * @secure
   */
  export namespace V1TenantViewGroupsImportFormViewGroupsList {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/tenant/ViewGroups/ImportFormViewGroups",
      method: "GET",
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = ImportFormInfoDtoViewGroupCollectionDtoResult;
  }

  /**
   * No description     * @tags ViewGroups
   * @name V1HostViewGroupsCreate
   * @request POST:/api/v1/host/ViewGroups
   * @secure
   */
  export namespace V1HostViewGroupsCreate {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/host/ViewGroups",
      method: "POST",
    };
    export type RequestQuery = {};
    export type RequestBody = CreateViewGroupRequestDto;
    export type RequestHeaders = {};
    export type ResponseBody = Int32Result;
  }

  /**
   * No description     * @tags ViewGroups
   * @name V1HostViewGroupsCollectionUpdate
   * @request PUT:/api/v1/host/ViewGroups/Collection
   * @secure
   */
  export namespace V1HostViewGroupsCollectionUpdate {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/host/ViewGroups/Collection",
      method: "PUT",
    };
    export type RequestQuery = {};
    export type RequestBody = UpdateViewGroupCollectionRequestDto;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description     * @tags ViewGroups
   * @name V1HostViewGroupsDelete
   * @request DELETE:/api/v1/host/ViewGroups/{id}
   * @secure
   */
  export namespace V1HostViewGroupsDelete {
    export type PathParams = {
      id: number;
    };
    export const BaseRequest = {
      url: "/host/ViewGroups/{id}",
      method: "DELETE",
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description     * @tags ViewGroups
   * @name V1HostViewGroupsDropdownList
   * @request GET:/api/v1/host/ViewGroups/Dropdown
   * @secure
   */
  export namespace V1HostViewGroupsDropdownList {
    export type PathParams = {};
    export const BaseRequest = {
      url: "/host/ViewGroups/Dropdown",
      method: "GET",
    };
    export type RequestQuery = {
      /** @format int32 */
      tenantId?: number;
      /** 1 = PowerBiDashboard, 2 = SsrsReport, 3 = ImportForm, 4 = PowerBiPaginatedReport */
      type?: ViewGroupType;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = ViewGroupInfoDtoListResult;
  }

  /**
   * No description     * @tags ViewGroups
   * @name V1HostViewGroupsPowerBiReportViewGroupsDetail
   * @request GET:/api/v1/host/ViewGroups/PowerBiReportViewGroups/{tenantId}
   * @secure
   */
  export namespace V1HostViewGroupsPowerBiReportViewGroupsDetail {
    export type PathParams = {
      tenantId: number;
    };
    export const BaseRequest = {
      url: "/host/ViewGroups/PowerBiReportViewGroups/{tenantId}",
      method: "GET",
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PowerBiReportInfoDtoViewGroupCollectionDtoResult;
  }

  /**
   * No description     * @tags ViewGroups
   * @name V1HostViewGroupsPowerBiPaginatedReportViewGroupsDetail
   * @request GET:/api/v1/host/ViewGroups/PowerBiPaginatedReportViewGroups/{tenantId}
   * @secure
   */
  export namespace V1HostViewGroupsPowerBiPaginatedReportViewGroupsDetail {
    export type PathParams = {
      tenantId: number;
    };
    export const BaseRequest = {
      url: "/host/ViewGroups/PowerBiPaginatedReportViewGroups/{tenantId}",
      method: "GET",
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PowerBiReportInfoDtoViewGroupCollectionDtoResult;
  }

  /**
   * No description     * @tags ViewGroups
   * @name V1HostViewGroupsSsrsReportViewGroupsDetail
   * @request GET:/api/v1/host/ViewGroups/SsrsReportViewGroups/{tenantId}
   * @secure
   */
  export namespace V1HostViewGroupsSsrsReportViewGroupsDetail {
    export type PathParams = {
      tenantId: number;
    };
    export const BaseRequest = {
      url: "/host/ViewGroups/SsrsReportViewGroups/{tenantId}",
      method: "GET",
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = SsrsReportInfoDtoViewGroupCollectionDtoResult;
  }

  /**
   * No description     * @tags ViewGroups
   * @name V1HostViewGroupsImportFormViewGroupsDetail
   * @request GET:/api/v1/host/ViewGroups/ImportFormViewGroups/{tenantId}
   * @secure
   */
  export namespace V1HostViewGroupsImportFormViewGroupsDetail {
    export type PathParams = {
      tenantId: number;
    };
    export const BaseRequest = {
      url: "/host/ViewGroups/ImportFormViewGroups/{tenantId}",
      method: "GET",
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = ImportFormInfoDtoViewGroupCollectionDtoResult;
  }
}
